import { createAsync, useLocation } from '@solidjs/router';
import { cachedGet } from '@troon/api-client';
import { Title } from '@solidjs/meta';
import { Button, Heading, Link, Section, Tab, TabList, Tabs, Tag, TextLink } from '@troon/ui';
import { createMemo, For, Show, Suspense } from 'solid-js';
import dayjs from '@troon/dayjs';
import { Content } from '../../../../../components/main-content';
import { TroonAccessTag } from '../../../../../components/troon-access-tag';
import { useCurrentFacility, useUser } from '../../../../../providers/root';
import { RedeemGuestPasses } from './[rewardsId]/components/redeem-guest-passes';
import type { ApiResponse } from '@troon/api-client';
import type { RouteSectionProps } from '@solidjs/router';

export default function Customer(props: RouteSectionProps) {
	const user = createAsync(() => getUser({ path: { rewardsId: props.params.rewardsId! } }), { deferStream: true });
	const location = useLocation<{ query?: string }>();
	const facility = useCurrentFacility();

	const format = new Intl.NumberFormat('en-us').format;

	return (
		<>
			<Content class="border-b border-neutral bg-white pb-0 lg:pb-0 2xl:pb-0">
				<Title>
					{user()?.firstName} {user()?.lastName} | Troon Operator
				</Title>

				<div class="flex flex-wrap items-center justify-between gap-4 border-b border-neutral pb-8">
					<div class="flex flex-col gap-4">
						<div class="flex gap-2">
							<TroonAccessTag product={user()?.accessProductType} />
							<For each={user()?.programs}>{(program) => <Tag appearance="neutral">{program.name}</Tag>}</For>
							<Show when={user()?.unlimitedGuestPasses}>
								<Tag appearance="neutral">Unlimited Guest Passes</Tag>
							</Show>
						</div>
						<Heading as="p" size="h1">
							{user()?.firstName} {user()?.lastName}
						</Heading>
						<p>{user()?.email}</p>
					</div>
					<Show when={user()?.availableGuestPasses ?? 0 > 0}>
						<RedeemGuestPasses {...user()!} />
					</Show>
				</div>

				<div class="flex flex-wrap gap-y-2 divide-x divide-neutral [&>div:first-child]:ps-0 [&>div]:px-4 [&>div]:2xl:px-12">
					<div class="flex flex-col gap-2">
						<span class="whitespace-nowrap text-sm font-medium text-neutral-700">Rewards #</span>
						<span class="text-xl font-semibold">{user()?.rewardsId}</span>
					</div>
					<div class="flex flex-col gap-2">
						<span class="whitespace-nowrap text-sm font-medium text-neutral-700">Current Status</span>
						<span class="text-xl font-semibold">{user()?.points.rewardsLevel}</span>
					</div>
					<div class="flex flex-col gap-2">
						<span class="whitespace-nowrap text-sm font-medium text-neutral-700">Available Points</span>
						<span class="text-xl font-semibold">{format(user()?.points.availablePoints ?? 0)}</span>
					</div>
					<div class="flex flex-col gap-2">
						<span class="whitespace-nowrap text-sm font-medium text-neutral-700">Available Guest Passes</span>
						<span class="text-xl font-semibold">
							{format(user()?.availableGuestPasses ?? 0)}
							<Show when={user()?.unlimitedGuestPasses}> (Unlimited)</Show>
						</span>
					</div>
					<div class="flex flex-col gap-2">
						<span class="whitespace-nowrap text-sm font-medium text-neutral-700">Rewards Member Since</span>
						<span class="text-xl font-semibold">{dayjs(user()?.signupDate).format('M/D/YYYY')}</span>
					</div>
				</div>

				<Show when={user() && !user()?.accessProductType && facility()?.facility.supportsTroonAccess}>
					<AccessSavingsCard user={user()!} />
				</Show>

				<Tabs defaultValue={location.pathname.split('/')[location.pathname.split('/').length - 1]}>
					<TabList aria-label="Customer details" class="border-b-0">
						<Tab
							as={Link}
							href={`/facility/${props.params.facility}/customer/${props.params.rewardsId}`}
							value="overview"
						>
							Overview
						</Tab>
						<Tab
							as={Link}
							href={`/facility/${props.params.facility}/customer/${props.params.rewardsId}/info`}
							value="info"
						>
							Personal Info
						</Tab>
					</TabList>
				</Tabs>
			</Content>
			<Content>
				<Suspense>{props.children}</Suspense>
			</Content>
		</>
	);
}

function AccessSavingsCard(props: { user: ApiResponse<'get', '/v0/operator/user/{rewardsId}'> }) {
	const facility = useCurrentFacility();
	const operator = useUser();

	const accessParams = createMemo(
		() =>
			new URLSearchParams({
				rewardsId: props.user.rewardsId,
				firstName: props.user.firstName ?? '',
				lastName: props.user.lastName ?? '',
				email: props.user.email ?? '',
				facilityId: facility()?.facility.id ?? '',
				operatorId: operator()?.id ?? '',
				operatorName: `${operator()?.firstName} ${operator()?.lastName}`,
			}),
	);

	return (
		<Section appearance="contained" class="my-8 bg-brand-700 text-white">
			<div class="flex items-center gap-8 text-white">
				<div class="flex grow flex-col gap-4">
					<p class="text-lg font-semibold">Troon Access Savings</p>
					<ul class="list-disc ps-6">
						<Show when={props.user.missedAccessSavings}>
							{(savings) => (
								<li class="text-base">
									{props.user.firstName} could have saved{' '}
									<span class="text-lg font-semibold">{savings().totalAccessDiscount.displayValue}</span> by joining
									Troon Access or{' '}
									<span class="text-lg font-semibold">
										{savings().totalAccessPlusDiscount.displayValue} by joining Troon Access+
									</span>
								</li>
							)}
						</Show>
						<Show when={props.user.potentialAccessSavings}>
							{(savings) => (
								<li class="text-base">
									{props.user.firstName} will save{' '}
									<span class="text-lg font-semibold">{savings().totalAccessDiscount.displayValue}</span> by joining
									Troon Access or joining Troon Access+. These saving will be applied automatically if they join now.
								</li>
							)}
						</Show>
					</ul>

					<p class="text-sm">
						Troon Access members save 15% on all tee times at all 150+ eligible courses. Troon Access+ members can save
						up to 50% off within 78 hours of the tee time.{' '}
						<TextLink href="https://troon.com/access" target="_blank" class="text-white underline">
							Click to see all benefits
						</TextLink>
					</p>
				</div>

				<Button
					as={Link}
					href={`https://troon.com/_/associate/access?${accessParams().toString()}`}
					class="size-fit shrink-0 grow-0"
				>
					Purchase Troon Access
				</Button>
			</div>
		</Section>
	);
}

const getUser = cachedGet('/v0/operator/user/{rewardsId}');
